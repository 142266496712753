<template>
  <div id="app">
    <div class="go-captcha-wrap">
      <GoCaptchaBtn
          v-model="captStatus"
          :image-base64="captBase64"
          :thumb-base64="captThumbBase64"
          class="go-captcha-btn"
          height="50px"
          width="100%"
          @confirm="handleConfirm"
          @refresh="handleRequestCaptCode"
      />
    </div>
  </div>
</template>

<script>
import GoCaptchaBtn from './components/GoCaptchaBtn'
import Qs from 'qs'
import githubBtn from '@/assets/github-btn'

export default {
  name: 'App',
  components: {
    GoCaptchaBtn,
  },
  data() {
    return {
      needCapt: false,
      popoverVisible: true,
      captBase64: '',
      captThumbBase64: '',
      captKey: '',
      captStatus: 'default',
      captExpires: 0,
      captAutoRefreshCount: 0,
    }
  },
  created() {
    githubBtn()
  },
  methods: {
    handleRequestCaptCode() {
      this.captBase64 = ''
      this.captThumbBase64 = ''
      this.captKey = ''

      this.$axios({
        method: 'get',
        url: '/captcha/data',
      }).then((response) => {
        const {data = {}} = response;
        if ((data['code'] || 0) === 0) {
          if (this.$lodash.isEmpty(data)) {
            return
          }

          this.captBase64 = data['image_base64'] || ''
          this.captThumbBase64 = data['thumb_base64'] || ''
          this.captKey = data['captcha_key'] || ''
        } else {
          this.$message({
            message: `Captcha data load failure`,
            type: 'warning'
          })
        }
      })
    },
    handleConfirm(dots) {
      if (this.$lodash.size(dots) <= 0) {
        this.$message({
          message: `CLICK TO VERIFY`,
          type: 'warning'
        })
        return
      }

      let dotArr = []
      this.$lodash.forEach(dots, (dot) => {
        dotArr.push(dot.x, dot.y)
      })

      this.$axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url: '/captcha/check',
        data: Qs.stringify({
          dots: dotArr.join(','),
          key: this.captKey
        })
      }).then((response) => {
        const {data = {}} = response;

        if ((data['code'] || 0) === 0) {
          // this.$message({
          //   message: `Success`,
          //   type: 'success'
          // })
          this.captStatus = 'success'
          this.captAutoRefreshCount = 0
          document.getElementById("register-btn").disabled = false;
          document.getElementById("register-btn").classList.remove("disabled");

        } else {
          // this.$message({
          //   message: `Failed`,
          //   type: 'warning'
          // })
          if (this.captAutoRefreshCount > 5) {
            this.captAutoRefreshCount = 0
            this.captStatus = 'over'
            return
          }

          this.handleRequestCaptCode()
          this.captAutoRefreshCount += 1
          this.captStatus = 'error'
        }
      })
    }
  }
}
</script>

<style>

.go-captcha-wrap {
  width: 100%;
  margin-bottom: 10px;
}

.go-captcha-btn {
  width: 100% !important;
  margin: 0 auto !important;
}

.wg-cap-tip {
  padding: 50px 0 100px;
  font-size: 13px;
  color: #76839b;
  text-align: center;
  line-height: 180%;
  width: 100%;
  max-width: 680px;
}

.wg-cap-tip a {
  display: inline-block;
  margin: 0 5px;
}

.wg-cap-tip a img {
  height: 28px;
}

.wg-cap-tip > span {
  margin: 0 5px;
}
</style>
